.contact-style {
  width: 100px;
  margin: 4px;
}
.contactBox {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 80px 1fr;
  border: 5px solid var(--main-five);
  border-radius: 10px;
  padding: 25px;
  grid-gap: 5px;
  column-gap: 10px;
  text-align: center;
  justify-items: center;
  margin: 36px;
}
.contact-style {
  width: 200px;
}
.contactDetails {
  grid-column: 1/5;
  grid-row: 1;
}
.contactLink {
  grid-row: 2;
}
.collapsibleContact {
  margin-bottom: 50px;
}
.contactInfo {
  text-decoration: underline;
}
.contactInfo:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
.contact-style {
  width: 100px;
  margin: 4px;
}
.contactBox {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 80px 1fr 1fr;
  border: 5px solid var(--main-five);
  border-radius: 10px;
  padding: 10px;
  grid-gap: 5px;
  column-gap: 10px;
  text-align: center;
  justify-items: center;
  margin: 36px;
}
.contact-style {
  width: 100px;
}
.contactDetails {
  grid-column: 1/3;
  grid-row: 1;
  font-size: small;
}
.collapsibleContact {
  margin-bottom: 50px;
}
.contactInfo {
  text-decoration: underline;
}
.contactInfo:hover {
  cursor: pointer;
}
#twitter{
  grid-column: 1;
  grid-row: 3;
}
#github{
  grid-column: 1;
  grid-row: 2;
}
#linkedin{
  grid-column: 2;
  grid-row: 2;
}
#mail{
  grid-column: 2;
  grid-row: 3;
}
}