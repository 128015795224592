.brandsig {
  display: flex;
  bottom: 0%;
  left: 0%;
  width: 100%;
  height: 50px;
  align-items: center;
}
.brandsig-content-center {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  bottom: 0%;
  left: 0%;
  width: 100%;
  height: 50px;
  background: var(--main-four);
}
.brandsig-content-left {
  position: fixed;
  bottom: 0%;
  left: 0%;
  padding-left: 10px;
  padding-top: 5px;
  margin-top: 10px;
  float: left;
  z-index: 1;
  color: white;
}
.logo p {
  color: #b1ff2e;
}
.brand p {
  font-family: "Gruppo", sans-serif;
  color: #b1ff2e;
  font-size: large;
  align-self: center;
  margin-bottom: 20px;
}
.sig p {
  color: white;
  padding-right: 7px;
}
.brandsig-content-right {
  position: fixed;
  bottom: 0%;
  right: 0%;
  padding-right: 10px;
  padding-top: 5px;
  margin-top: 10px;
  float: right;
}
.icon-style {
  height: 30px;
  margin-left: 20px;
  margin-top: 0px;
 margin-bottom: -10px;
  border: 0px;
}
.icon-style:hover {
  background: var(--main-five);
  padding: 1px;
}
.techStack {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 5px;
  margin-bottom: 0px;
  z-index: 1;
}
.techStackImg {
  max-width: 30px;
}
.cvDownload {
  border-radius: 10px;
  background-color: var(--main-one);
  color: var(--main-four);
  height: 30px;
  margin-bottom: 10px;
}
.cvDownload:hover {
  background-color: var(--main-five);
}

@media only screen and (max-width: 600px) {
  .brandsig {
    display: flex;
    bottom: 0%;
    left: 0%;
    width: 100%;
    height: 50px;
  }
  .brandsig-content-center {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    position: fixed;
    bottom: 0%;
    left: 0%;
    width: 100%;
    height: 50px;
    background: #464545;
  }
  .logo p {
    color: #b1ff2e;
  }
  .brand p {
    font-family: "Gruppo", sans-serif;
    color: #b1ff2e;
    font-size: large;
    align-self: center;
    margin-bottom: 20px;
  }
  .sig p {
    color: white;
    padding-right: 7px;
  }
  .brandsig-content-right {
    display: flex;
    position: fixed;
    bottom: 0%;
    right: 0%;
    padding-right: 10px;
    padding-top: 5px;
    margin-top: 10px;
    justify-content: right;
  }
  .icon-style {
    display: none;
    height: 30px;
    margin-left: 20px;
    margin-top: 0px;
    margin-bottom: 5px;
    border: 0px;
  }
  .icon-style:hover {
    background: #b1ff2e;
    padding: 2px;
  }
  .cvDownload {
  border-radius: 10px;
  background-color: var(--main-one);
  color: var(--main-four);
  height: 35px;
  max-width: 20vw;
  margin-bottom: 10px;
}
.cvDownload:hover {
  background-color: var(--main-five);
}
.techStack{
  font-size: small;
  max-width: 70vw;
}
.techStackImg {
  max-width: 20px;
}
}
